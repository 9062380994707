import React, { useState, useEffect } from "react"
import Logo from "../images/res-logo.svg"
import { Link } from "gatsby"
import SignupModal from "./signupModal"
import styled from "styled-components"
import { AuthProvider, AuthConsumer } from "./utils/AuthContext"

const NavbarScroll = () => {
  const [burger, setBurger] = useState(false)

  const handleLogin = () => {
    document.querySelector(".signup-modal").classList.toggle("is-active")
  }

  const handleSubmit = () => {
    document.querySelector(".submit-modal").classList.toggle("is-active")
  }

  useEffect(() => {
    window.onscroll = function () {
      if (window.pageYOffset > 200) {
        document.querySelector(".navbar.is-fixed-top").classList.add("scrolled")
      } else {
        document
          .querySelector(".navbar.is-fixed-top")
          .classList.remove("scrolled")
      }
    }
  }, [])

  return (
    <AuthProvider>
      <AuthConsumer>
        {({ isAuth, logout, user }) => (
          <StyledNav
            className="navbar is-fixed-top"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="container">
              <div className="navbar-brand">
                <Link className="navbar-item" to="/">
                  <img src={Logo} width="112" height="28" alt="GrowKarma" />
                </Link>

                <a
                  role="button"
                  className={
                    burger
                      ? "navbar-burger burger is-active"
                      : "navbar-burger burger"
                  }
                  onClick={() => setBurger(!burger)}
                >
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </a>
              </div>

              <div className={burger ? "navbar-menu is-active" : "navbar-menu"}>
                <div className="navbar-end">
                  <div className="navbar-item">
                    {!isAuth ? (
                      <a
                        onClick={() => handleLogin()}
                        className=" button is-warning"
                      >
                        Submit a resource
                      </a>
                    ) : (
                      <Link className=" button is-warning" to="/submit">
                        Submit a resource
                      </Link>
                    )}
                  </div>
                  <Link to="/newsletter" className="navbar-item">
                    Newsletter
                  </Link>
                  {isAuth ? (
                    <div className="navbar-item has-dropdown is-hoverable">
                      <a className="navbar-link" id="copy-email">
                        Account
                      </a>
                      <div className="navbar-dropdown">
                        <p className="navbar-item">{user.username}</p>
                        <a className="navbar-item" onClick={e => logout(e)}>
                          Logout
                        </a>
                      </div>
                    </div>
                  ) : (
                    <a onClick={() => handleLogin()} className="navbar-item">
                      Login
                    </a>
                  )}
                </div>
              </div>
            </div>
            {!isAuth ? <SignupModal /> : ""}
          </StyledNav>
        )}
      </AuthConsumer>
    </AuthProvider>
  )
}

export default NavbarScroll

const StyledNav = styled.nav`
  top: -100% !important;
  transition: all 0.35s ease-in-out;
  &.scrolled {
    top: 0 !important;
  }
`

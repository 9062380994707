import React from "react"
import PropTypes from "prop-types"
import Navbar from "./navbar"
import NavbarScroll from "./navbar-scroll"
import Footer from "./footer"

import Seo from "./seo"

const Layout = ({ children }) => {
  return (
    <>
      <Seo title={""} />
      <Navbar />
      <NavbarScroll />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
